import { http } from "@/plugin/http";

// 登录认证
export const authLogin = async (params: any) => {
  try {
    const { access_token } = (await http.post("/logon/", params)) || {};
    return access_token || null;
  } catch (error) {
    return null;
  }
};

// 注册验证码
export const registerCode = async (params: any) => {
  try {
    await http.get("/send_sms_login/", params);
    return true;
  } catch (error) {
    return false;
  }
};

// 注册
export const register = async (params: any) => {
  try {
    await http.post("/login/", params);
    return true;
  } catch (error) {
    return false;
  }
};

// 获取用户信息
export const getUserInfo = async () => {
  try {
    return await http.get("/userinfo/");
  } catch (error) {
    return null;
  }
};
