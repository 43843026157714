import type { AxiosRequestConfig } from "axios";
import axios from "axios";
import { ElMessage } from "element-plus";

import { useBasic } from "@/store/basic";

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  timeout: 60000,
});

class Http {
  request(config: AxiosRequestConfig) {
    const basic = useBasic();

    if (config.headers && config.headers.responseType !== void 0) {
      config.responseType = config.headers.responseType;
    }

    config.headers = {
      Authorization: `Bearer ${basic.authorization}`,
      ...(config.headers || {}),
    };

    return new Promise<any>((resolve, reject) => {
      axiosInstance<any>(config)
        .then((res) => {
          if (config.headers && config.headers.responseType !== void 0) {
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement("a");
            a.setAttribute("style", "display: none");
            a.download = "导出.xlsx";
            a.href = url;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            return resolve(res.data);
          }

          if (res.data.code === "1" || res.data.code === 1) {
            resolve(res.data.data);
          } else {
            ElMessage.warning(res.data?.message || "请求出错");
            reject(null);
          }
        })
        .catch(() => {
          ElMessage.warning("请求出错");
          reject(null);
        });
    });
  }

  get(url: string, params?: unknown, headers?: AxiosRequestConfig["headers"]) {
    return this.request({ method: "get", url, headers, params });
  }

  post(
    url: string,
    data?: unknown,
    params?: unknown,
    headers?: AxiosRequestConfig["headers"]
  ) {
    return this.request({ method: "post", url, headers, params, data });
  }

  put(
    url: string,
    data?: unknown,
    params?: unknown,
    headers?: AxiosRequestConfig["headers"]
  ) {
    return this.request({ method: "put", url, headers, params, data });
  }

  delete(
    url: string,
    params?: unknown,
    data?: unknown,
    headers?: AxiosRequestConfig["headers"]
  ) {
    return this.request({ method: "delete", url, headers, params, data });
  }
}

export const http = new Http();
