export default [
  {
    path: "/contract",
    name: "contract",
    meta: {
      icon: "icon-hetong",
      title: "合同管理",
    },
    children: [
      {
        path: "/contract/contract-example",
        name: "contractExample",
        meta: {
          title: "样本管理",
        },
        component: () => import("@/views/Contract/Example/index.vue"),
      },
      {
        path: "/contract/sign",
        name: "sign",
        meta: {
          title: "签署管理",
        },
        component: () => import("@/views/Contract/Sign/index.vue"),
      },
    ],
  },
  {
    path: "/infoManage",
    name: "infoManage",
    meta: {
      icon: "icon-a-137-xinxiguanli",
      title: "信息管理",
    },
    component: () => import("@/views/InfoManage/index.vue"),
  },
];
