import { ElLoading } from "element-plus";
import { createRouter, createWebHistory } from "vue-router";

import { useBasic } from "@/store/basic";

import { mergeRouteModules } from "./utils";

const adminRoutes = import.meta.glob("./admin/**/*.ts", { eager: true });
const customerRoutes = import.meta.glob("./customer/**/*.ts", { eager: true });

export const menus = mergeRouteModules({
  ...adminRoutes,
  ...customerRoutes,
});

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "layout",
      redirect: menus[0].path,
      component: () => import("@/layout/Layout.vue"),
      children: menus,
    },
    {
      path: "/auth",
      name: "auth",
      meta: {
        needLogin: false,
      },
      redirect: "login",
      component: () => import("@/views/Auth/index.vue"),
      children: [
        {
          path: "login",
          name: "Login",
          meta: {
            needLogin: false,
          },
          component: () => import("@/views/Auth/components/Login/index.vue"),
        },
        {
          path: "register",
          name: "Register",
          meta: {
            needLogin: false,
          },
          component: () => import("@/views/Auth/components/Register/index.vue"),
        },
      ],
    },
  ],
});

router.beforeEach(async (to: any) => {
  const basic = useBasic();

  if (to.meta.needLogin === false && basic.authorization) {
    return "/";
  }

  if (to.meta.needLogin !== false && !basic.authorization) {
    return "/auth/login";
  }

  if (!basic.userInfo && to.meta.needLogin !== false) {
    const loading = ElLoading.service({ fullscreen: true, text: "加载中" });
    await basic.getUserInfo();
    loading.close();
  }

  return true;
});

export default router;
