export default [
  {
    path: "/user",
    name: "user",
    meta: {
      icon: "icon-yonghuxinxi",
      title: "用户管理",
    },
    component: () => import("@/views/User/user.vue"),
  },
];
