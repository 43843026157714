import { defineStore } from "pinia";

import { getUserInfo } from "@/api/login";

const StorageTokenKey = "authorization";

export const useBasic = defineStore("basic", {
  state: () => ({
    userInfo: null,
    token: null,
  }),
  getters: {
    authorization: (state: any) => {
      if (state.token) {
        return state.token;
      }
      return localStorage.getItem(StorageTokenKey) || null;
    },
  },
  actions: {
    setUserInfo(userInfo: any) {
      this.userInfo = userInfo;
    },
    setToken(token: string) {
      localStorage.setItem(StorageTokenKey, token);
      this.token = token;
    },
    async getUserInfo() {
      this.setUserInfo(await getUserInfo());
    },
  },
});
