import "./styles/main.less";
import "virtual:windi-components.css";
import "virtual:windi-utilities.css";
import "element-plus/dist/index.css";
import "@/assets/font/iconfont.css";

import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import { createPinia } from "pinia";
import { createApp } from "vue";

import router from "@/router";

import App from "./App.vue";

const app = createApp(App);
app.use(ElementPlus, {
  locale: zhCn,
});
app.use(createPinia());
app.use(router);
app.mount("#app");
